$backgroundColor: #fafafa;
$first: #0062A1;
$grey: #DEDDDD;
$white: #ffffff;


$fullHDWidth: 1920px;
$largeWidth: 1600px;
$tabletWidth: 1280px;
$mobileWidth: 768px;

$rosePageWidth: 1150px;

$headerDesktopHeight: 80px;
$headerTabletHeight: 60px;
