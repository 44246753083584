@import '../../../../css/constants.module';

.Container {
  width: 100%;
  padding-bottom: 40px;

  .Paper {
    padding: 40px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $tabletWidth) {
      display: block;
    }

    @media screen and (max-width: $mobileWidth) {
      padding: 20px;
    }

    .Left {
      width: 25%;

      .CurrentView {
        font-size: 13px;
      }

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
      }

      .Region {
        display: flex;
        align-items: center;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }

      .RegionSelect {
        display: none;

        @media screen and (max-width: $tabletWidth) {
          display: block;
          margin-top: 20px;
        }
      }

      .Map {
        margin-top: 20px;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }
    }

    .Right {
      width: 75%;
      padding-left: 20px;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
      }

      .MenuItem {

        @media screen and (max-width: $mobileWidth) {
          height: auto !important;
          white-space: normal !important;
        }
      }
      .Row {
        display: flex;
        flex-direction: row;
        align-items: center;
        margin: 20px 0 10px 0;
      }
    }

    .Column {
    }

    .ActiveText {
      cursor: pointer;
      text-decoration: underline;
    }
  }
}
