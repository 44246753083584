@import '../../css/constants.module';

.Container{
  .Map {
    cursor: pointer;
    width: 90%;
  }

  .MapDisabled {
    width: 90%;
  }
}
