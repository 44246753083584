/**
*   RESET CSS
*/


/* http://meyerweb.com/eric/tools/css/reset/
   v2.0 | 20110126
   License: none (public domain)
*/

html, body, div, span, applet, object, iframe,
h1, h2, h3, h4, h5, h6, p, blockquote, pre,
a, abbr, acronym, address, big, cite, code,
del, dfn, em, img, ins, kbd, q, s, samp,
small, strike, strong, sub, sup, tt, var,
b, u, i, center,
dl, dt, dd, ol, ul, li,
fieldset, form, label, legend,
table, caption, tbody, tfoot, thead, tr, th, td,
article, aside, canvas, details, embed,
figure, figcaption, footer, header, hgroup,
menu, nav, output, ruby, section, summary,
time, mark, audio, video {
    margin: 0;
    padding: 0;
    border: 0;
    font-size: 100%;
    font: inherit;
    vertical-align: baseline;
}
/* HTML5 display-role reset for older browsers */
article, aside, details, figcaption, figure,
footer, header, hgroup, menu, nav, section {
    display: block;
}
body {
    line-height: 1;
}
ol, ul {
    list-style: none;
}
blockquote, q {
    quotes: none;
}
blockquote:before, blockquote:after,
q:before, q:after {
    content: '';
    content: none;
}
table {
    border-collapse: collapse;
    border-spacing: 0;
}
input {
    font-family: 'PT Sans', sans-serif;
}

/**
* STYLES
**/

body, html {
    min-height: 100%;
    width: 100%;
    font-family: 'PT Sans', sans-serif;
    font-size: 16px;
}

#main-content-wrapper {
    min-height: 100%;
    width: 100%;
}

* {
    box-sizing: border-box;
    outline: none;
    box-shadow: none;
}

input:-webkit-autofill,
input:-webkit-autofill:hover,
input:-webkit-autofill:focus,
input:-webkit-autofill:active {
    transition: all 5000s ease-in-out 0s;
}

.rc-switch {
    position: relative;
    display: inline-block;
    box-sizing: border-box;
    width: 44px;
    height: 22px;
    line-height: 20px;
    vertical-align: middle;
    border-radius: 20px 20px;
    border: 1px solid #ccc;
    background-color: #ccc;
    cursor: pointer;
    -webkit-transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: all 0.3s cubic-bezier(0.35, 0, 0.25, 1);
}
.rc-switch-inner {
    color: #fff;
    font-size: 12px;
    position: absolute;
    left: 24px;
}
.rc-switch:after {
    position: absolute;
    width: 18px;
    height: 18px;
    left: 2px;
    top: 1px;
    border-radius: 50% 50%;
    background-color: #ffffff;
    content: " ";
    cursor: pointer;
    box-shadow: 0 2px 5px rgba(0, 0, 0, 0.26);
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    transition: left 0.3s cubic-bezier(0.35, 0, 0.25, 1);
    -webkit-animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    animation-timing-function: cubic-bezier(0.35, 0, 0.25, 1);
    -webkit-animation-duration: 0.3s;
    animation-duration: 0.3s;
    -webkit-animation-name: rcSwitchOff;
    animation-name: rcSwitchOff;
}
.rc-switch:hover:after {
    -webkit-transform: scale(1.1);
    transform: scale(1.1);
    -webkit-animation-name: rcSwitchOn;
    animation-name: rcSwitchOn;
}
.rc-switch:focus {
    box-shadow: 0 0 0 2px #d5f1fd;
    outline: none;
}
.rc-switch-checked {
    border: 1px solid #87d068;
    background-color: #87d068;
}
.rc-switch-checked .rc-switch-inner {
    left: 6px;
}
.rc-switch-checked:after {
    left: 22px;
}
.rc-switch-disabled {
    cursor: no-drop;
    background: #ccc;
    border-color: #ccc;
}
.rc-switch-disabled:after {
    background: #9e9e9e;
    -webkit-animation-name: none;
    animation-name: none;
    cursor: no-drop;
}
.rc-switch-disabled:hover:after {
    -webkit-transform: scale(1);
    transform: scale(1);
    -webkit-animation-name: none;
    animation-name: none;
}
.rc-switch-label {
    display: inline-block;
    line-height: 20px;
    font-size: 14px;
    padding-left: 10px;
    vertical-align: middle;
    white-space: normal;
    pointer-events: none;
    -webkit-user-select: text;
    -moz-user-select: text;
    -ms-user-select: text;
    user-select: text;
}
@-webkit-keyframes rcSwitchOn {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
    }
    100% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}
@keyframes rcSwitchOn {
    0% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
    50% {
        -webkit-transform: scale(1.25);
        transform: scale(1.25);
    }
    100% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
}
@-webkit-keyframes rcSwitchOff {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
@keyframes rcSwitchOff {
    0% {
        -webkit-transform: scale(1.1);
        transform: scale(1.1);
    }
    100% {
        -webkit-transform: scale(1);
        transform: scale(1);
    }
}
