@import './constants.module';

.Container {
  width: 100%;
  min-height: 100vh;
  background: $backgroundColor;
}

.Wrapper {
  width: 95%;
  max-width: $fullHDWidth;
  margin: 0 auto;

  @media screen and (min-width: $largeWidth) {
    width: 95%;
    max-width: 1920px;
  }

  @media screen and (max-width: $mobileWidth) {
    padding: 0 10px;
  }

}

.NBold {
  font-family: "Nunito-Bold", sans-serif !important;
  font-size: 14px !important;
  font-weight: normal !important;
  margin: 0!important;
}
