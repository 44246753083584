@import "../../../../css/constants.module";

.Container {
  width: 100%;
  padding-bottom: 40px;


  .ActiveText {
    cursor: pointer;
    text-decoration: underline;
  }

  .JustifiedRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .OrderRow {
    display: none;
    @media screen and (max-width: $tabletWidth) {
      display: flex;
      justify-content: flex-end;
      margin-bottom: 20px;
    }

    .OrderFab {
      color: white;

      &:not(:last-child) {
        margin-right: 10px;
      }
    }
  }

  .Paper {
    padding: 40px;
    display: block;

    @media screen and (max-width: $tabletWidth) {
      padding: 30px 20px;
      display: block;
    }

    .Left {
      width: 25%;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
      }

      .RegionSelect {
        display: none;

        @media screen and (max-width: $tabletWidth) {
          display: block;
          margin: 10px 0 50px;
        }
      }

      .CurrentView {
        font-size: 13px;
      }

      .Map {
        margin-top: 20px;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }

      .TooltipPaper {
        min-width: 200px;
        min-height: 200px;
        flex: 1;
        margin: 20px;
        padding: 10px;
      }
    }

    .Right {
      width: 75%;
      padding-left: 60px;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
        padding: 0;
      }

      .TableInfo {
        font-size: 13px;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }

      .Table {
        @media screen and (max-width: $tabletWidth) {
          display: none;
        }

        .TableFirstRow1 {
          width: 23%;
        }

        .TableFirstRow2 {
          width: 14%;
        }

        .TableFirstRow3 {
          width: 20%;
        }

        .TableFirstRow4 {
          width: 23%;
        }

        .TableFirstRow5 {
          width: 20%;
        }

        .TableCellInfo {
          display: block;
        }

        .DiffTypography {
          margin: auto;
          min-width: 60px;
          font-size: 14px
        }

        .DiffChart {
          flex: 1;
        }

        .TableCellTitle {
          font-family: "Tofino-Bold", sans-serif;
          font-size: 14px;
          opacity: 1;


          @media screen and (max-width: $tabletWidth) {

          }
        }

        .DiffLabel {
          font-size: 14px;
          opacity: .75;
        }
      }

    }

    .Number {
      display: flex;
      font-size: 14px;
      opacity: 1;
    }

    .Link {
      display: block;
      max-width: 200px;
    }
  }

  .LeftFull {
    width: 100%;
  }

  .Full {
    width: 100%;
    padding: 20px;
  }

  .LabelCell {
    padding-right: 20px;
  }

  .Legend {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;

    .LegendTypography {
      margin: auto 0
    }

    .Spot {
      margin: 5px 5px 0 0;
    }
  }

  .LegendContainer {
    display: flex;
    margin-top: 10px;
    margin-bottom: 20px;
    min-height: 10px;

    @media screen and (max-width: $tabletWidth) {
      display: block;
      margin-bottom: 0;
    }

    .Legend {
      display: flex;
      justify-content: flex-start;
      align-items: flex-start;
      margin-right: 20px;

      .LegendTypography {
        margin: auto 0
      }

      .LegendSpot {
        margin: 5px 5px 5px 0px;
      }
    }

  }

  .GlobalRow {
    display: flex;
    width: 100%;
    justify-content: space-between;
    align-items: flex-end;

    &:not(:last-child):not(:nth-child(2)) {
      margin-bottom: 30px;
    }

    &:last-child {
      margin-top: 20px;
    }

    @media screen and (max-width: $tabletWidth) {
      display: block;
      align-items: flex-start;
    }

    .SearchContainer {
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 400px;

      @media screen and (max-width: $tabletWidth) {
        margin-bottom: 20px;
        width: 100%;
      }


      .Input {
        flex: 1;

        @media screen and (max-width: $tabletWidth) {
          flex: 1;
          width: auto;
        }

        @media screen and (max-width: $mobileWidth) {
          flex: 1;
          width: auto;
        }

      }

      .SearchFab {
        width: 56px;
        height: 56px;
        margin-left: 10px;
      }

    }

    .TitleContainer {
      max-width: 70%;
      padding-right: 30px;


      @media screen and (max-width: $tabletWidth) {
        max-width: 100%;
        padding-right: 0;
        margin-top: 30px;
      }

      .Title {

        @media screen and (max-width: $tabletWidth) {
        }
      }

    }

    .Info{
      margin-bottom: 30px;
      letter-spacing: 0;
    }

  }

  .Row {
    width: 100%;
    display: block;
    margin-bottom: 30px;

    @media screen and (min-width: $mobileWidth) {
      display: flex;
      justify-content: space-between;
    }

    @media screen and (min-width: $tabletWidth) {
    }

    .ResultRoseColumn {
      display: block;
      justify-content: center;
      margin: 20px auto 0;
      width: 100%;

      .ResultRoseColumnCells {
        display: flex;
        margin-bottom: 30px;

        @media screen and (max-width: $rosePageWidth) {
          display: block;
        }

        .RoseCell {
          display: block;
          justify-content: center;
          margin: 0 auto;
          width: 50%;


          @media screen and (max-width: $rosePageWidth) {
            width: 100%;
          }

          .RoseChart {
            display: flex;
            justify-content: center;
            max-width: 100%;
            margin-top: 20px;

            div {
              width: 100%;
            }

            svg {
              width: 100%;
            }

          }

          .RoseTypography {
            font-size: 15px;
            margin-bottom: 20px;

            &:before {
              content: '';
              position: absolute;
              width: 30px;
              height: 4px;
              background: black;
              margin-top: -10px;
            }
          }
        }
      }
    }

    .ResultColumnJob {
      width: 100%;
      padding-right: 0;
      margin-bottom: 30px;

      @media screen and (min-width: $mobileWidth) {
        width: 50%;
        padding-right: 20px;
      }

      @media screen and (min-width: $tabletWidth) {
        width: 30%;
        padding-right: 80px;
      }

      .TypographyH3 {
        font-size: 18px;

        &:before {
          content: '';
          position: absolute;
          width: 30px;
          height: 4px;
          background: black;
          margin-top: -10px;
        }
      }

      .ResultRowTitleJob {
        display: flex;
        justify-content: space-between;
        height: 70px;

        @media screen and (max-width: $tabletWidth) {
          height: auto;
          margin-bottom: 10px;
        }
      }

      .ResultRowJob {
        display: flex;
        justify-content: space-between;
        height: 70px;
        .Label {
          height: 60px;
          min-width: 120px;
          width: 120px;
          align-items: center;
          display: flex;

          .LabelText {
            font-family: "Tofino-Bold";
            font-weight: bold;
          }

          @media screen and (max-width: $tabletWidth) {
            height: auto;
            width: auto;
            min-width: auto;
          }
        }

        .Value {
          height: 60px;
          align-items: center;
          display: flex;
min-width: 50px;
          @media screen and (max-width: $tabletWidth) {
            height: auto;
            width: auto;
            min-width: auto;
          }

          .ValueTypographyH3 {
            font-size: 18px;
          }
        }

        @media screen and (max-width: $tabletWidth) {
          border-bottom: 1px solid rgba(0, 0, 0, 0.23);
          padding: 5px 0;
        }

        &:last-child {
          border-bottom: none;
        }
      }

    }

    .ResultColumn {
      display: block;
      flex: 1;
      margin-top: 60px;
      //justify-content: center;

      @media screen and (min-width: $mobileWidth) {
        height: auto;
        width: 50%;
      }

      @media screen and (min-width: $tabletWidth) {
        height: auto;
        width: 70%;
        margin-top: 0;
      }

      .ResultColumnTitle {
        display: flex;
        flex-direction: column;
        height: auto;
        min-height: 70px;

        @media screen and (max-width: $tabletWidth) {
          height: auto;
        }
        .TypographyH3 {
          font-size: 18px;

          &:before {
            content: '';
            position: absolute;
            width: 30px;
            height: 4px;
            background: black;
            margin-top: -10px;
          }
        }
      }

      .ResultColumnCells {
        display: block;

        @media screen and (min-width: $mobileWidth) {
          display: flex;
          max-width: 100%;
        }


        .BarName {
          margin: auto 0;
          font-size: 10px;
          width: 70px;
        }

        .Cell {
          display: block;
          justify-content: center;
          text-align: center;
          margin: 0 auto;
          width: 100%;


          .Chart {
            display: block;

            @media screen and (min-width: $tabletWidth) {
              display: flex;
              justify-content: space-between;
              margin-bottom: 10px;
              align-items: center;
            }

            .ChartContainer {
              width: calc(100% - 220px);
              height: 50px;
              display:flex;
              @media screen and (max-width: $tabletWidth) {
                width: 100%
              }

              div {
                border-radius: 25px;
                max-width: 100%;

              }

            }

            .ChartInfo {
              display: flex;
              justify-content: space-between;
              align-items: center;
              height: 60px;


              .ChartTotal {
                font-size: 18px;
                width: 80px;
              }

              .ChartLabel {
                text-align: left;
                min-width: 120px;
                width: 120px;
                font-family: "Tofino-Bold";
              }
            }
          }
        }


        &.Pie {
          .Cell {
            margin-left: -20px;

            @media screen and (max-width: $tabletWidth) {
              margin-left: auto;
              margin-right: auto;
            }

          }
        }

      }

    }
  }

}

.TableColumn {
  display: flex;
  flex-direction: column;
  margin: 20px 0 0;
}

.AccordionContainer {
  display: none;

  @media screen and (max-width: $tabletWidth) {
    display: block;

  }

  .AccordionTable {
    padding: 0 ;
    max-width: 100%;
    width: 100%;

    .AccordionTableRow {
      padding: 0;
    }

    .AccordionTableCell {
      display: block;
      padding: 0;
      max-width: 100%;

      .TableCellTitle {
        font-family: "Tofino-Bold", sans-serif;
        font-size: 14px;
        opacity: 1;
        padding-top:20px;
      }

      .Divider {
        margin-top: 15px;
      }
    }

  }

  .ExpansionPanel {
    box-shadow: none;
  }

  .ExpansionPanelSummaryFill {
    background-color: #F7F6FB;
  }

  .ExpansionPanelDetails {
    padding: 8px 0px 24px;
  }


}

.SkillSvgContainer {
  display: flex;
  height: 25px;
  align-items: center;
  margin: 10px 0;

  @media screen and (max-width: $tabletWidth) {
    display: block;
    height: auto;
    text-align: center;
    margin: 20px 0;
    border: 1px solid rgba(0, 0, 0, 0.23);
    padding: 30px;
    border-radius: 4px;
  }

  .SkillsSvg {
    width: 25px;
    height: auto;
    fill: #0062A1 !important;
  }
}

.RowReverse {

  @media screen and (max-width: $tabletWidth) {
    display: flex !important;
    flex-direction: column-reverse;
  }
}

.RegionTitle {
  display: flex;
  align-items: center;
  height: 30px;
  margin-top: 10px;
}

.SkillsContainer {
  margin-bottom: 30px;
}

.Other {
  font-size: 14px !important;
  line-height: 1.5 !important;
  margin-top: 10px !important;
}

.Other2 {
  font-size: 14px !important;
  line-height: 1.5 !important;
  margin-top: 15px !important;
}

.Image {
  height: 28px;
}

.CircularProgress {
  margin: 100px 40%;
}

.Progress {
  width: 90%;
  max-width: 90%;
  margin: auto;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.InfoMessage {
  cursor: pointer;
  color: #5bbb73 !important;
}
