@import "../../../../css/constants.module";

.Container {
  width: 100%;
  padding-bottom: 40px;

  .ActiveText {
    cursor: pointer;
    text-decoration: underline;
  }

  .JustifiedRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Spot {
    margin: 5px 5px 5px 20px;
  }

  .Paper {
    padding: 40px;
    display: block;

    @media screen and (max-width: $tabletWidth) {
      padding: 30px 20px;
      display: block;
    }

    .Left {
      width: 25%;

      .CurrentView {
        font-size: 13px;
      }

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
      }

      .Region {
        display: flex;
        align-items: center;
      }

      .RegionSelect {
        display: none;

        @media screen and (max-width: $tabletWidth) {
          display: block;
          margin: 20px 0 20px;
        }
      }

      .Map {
        margin-top: 40px;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }
    }

    .Right {
      width: 75%;
      padding-left: 20px;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
        padding: 0;
      }

      .Table {
        @media screen and (max-width: $tabletWidth) {
          display: none;
        }

        .TableCellInfo {
          display: block;
        }

        .TableLink {
          cursor: pointer;
          color: #5BBB75;
        }

        .DiffTypography {
          margin: auto;
          min-width: 60px;
          font-size: 14px
        }

        .DiffChart {
          flex:1;
        }

        .TableCellTitle {
          font-family: "Tofino-Bold", sans-serif;
          font-size: 14px;
          opacity: 1;
        }

        .DiffLabel {
          font-size: 14px;
          opacity: .75;
        }
      }


      .Legend {
        display: flex;
        justify-content: flex-start;
        align-items: flex-start;
        .LegendTypography {
          margin: auto 0
        }
        .Spot {
          margin: 5px 5px 0 0;
        }
      }

      .Number {
        display: flex;
        font-size: 14px;
        opacity: 1;
      }

      .LabelCell {
        padding-right: 20px;
      }

      .TableFirstRow1 {
        width:23%;
      }

      .TableFirstRow2 {
        width:14%;
      }

      .TableFirstRow3 {
        width:20%;
      }

      .TableFirstRow4 {
        width:23%;
      }

      .TableFirstRow5 {
        width:20%;
      }


    }

    .Column {
      display: flex;
      flex-direction: column;
    }

    .Row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }


    .AccordionContainer {
      display: none;

      @media screen and (max-width: $tabletWidth) {
        display: block;
      }

      .AccordionTable {
        padding: 0 ;
        max-width: 100%;
        width: 100%;

        .AccordionTableRow {
          padding: 0;
        }

        .AccordionTableCell {
          display: block;
          padding: 0;
          max-width: 100%;

          .TableCellTitle {
            font-family: "Tofino-Bold", sans-serif;
            font-size: 14px;
            opacity: 1;
            padding-top:20px;
          }

          .Divider {
            margin-top: 15px;
          }
        }

      }

      .ExpansionPanel {
        box-shadow: none;
      }

      .ExpansionPanelSummaryFill {
        background-color: #F7F6FB;
      }

      .ExpansionPanelDetails {
        padding: 8px 0px 24px;
      }


    }
  }
}

.LegendContainer {
  display: flex;
  margin-top: 10px;

  @media screen and (max-width: $tabletWidth) {
    display: block;
  }

  .Legend {
    display: flex;
    justify-content: flex-start;
    align-items: flex-start;
    margin-right: 20px;

    .LegendTypography {
      margin: auto 0
    }
    .LegendSpot {
      margin: 5px 10px 5px 0px;
    }
  }

}

.OrderRow {
  display: none;
  @media screen and (max-width: $tabletWidth) {
    display: flex;
    justify-content: flex-end;
    margin-bottom: 20px;
  }
  .OrderFab {
    color: white;
    &:not(:last-child) {
      margin-right: 10px;
    }
  }
}

.GlobalRow {
  display: flex;
  width: 100%;
  justify-content: space-between;

  &:not(:last-child):not(:nth-child(2)) {
    margin-bottom: 30px;

    @media screen and (max-width: $tabletWidth) {
      margin-bottom: 0;
    }
  }

  &:last-child {
    margin-top: 30px;

    @media screen and (max-width: $tabletWidth) {
      margin-top: 0;
    }
  }

  @media screen and (max-width: $tabletWidth) {
    display: block;
    align-items: flex-start;
  }

  .GlobalBlock {
    display: block;
    margin-bottom: 10px;
    @media screen and (min-width: $tabletWidth) {
      width: 50%;
    }

  }


  .Groups {
    display: block;
    align-items: flex-end;
    @media screen and (max-width: $tabletWidth) {
      padding: 20px 0;
    }
  }

  .LinkTitle {
    margin-bottom: 10px;
  }

  .Link {
    display: block;
    font-family: "Tofino-Bold", sans-serif;
    opacity: 1;
    margin-bottom: 10px
  }

  .TypographyLink {
    font-size: 14px;
    font-family: "Tofino-Bold", sans-serif;
    opacity: 1
  }

  .TableInfo {
    font-size: 13px;

    @media screen and (max-width: $tabletWidth) {
      display: none;
    }
  }
}
