@import '../../css/constants.module';

.AppBar {
  height: $headerDesktopHeight;
  background-color: white;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $tabletWidth) {
    height: $headerTabletHeight;
  }

  .ToolBar {
    display: flex;
    justify-content: space-between;
    padding-left: 0;
    padding-right: 0;

    .Buttons {
      display: flex;
      justify-content: center;
      width: 60%;

      @media screen and (max-width: $mobileWidth) {
        display: none;
      }

      .Button {
        &:not(:last-child) {
          margin-right: 40px;
        }
      }
    }

    .Logo {
      height: auto;
      width: 160px;

      @media screen and (max-width: $tabletWidth) {
        width: 120px;
      }
    }


    .PathIcon {
      &:not(:last-child) {
        margin-right: 15px;
      }
    }

    .Socials {
      height: auto;
      display: flex;
      flex-direction: row;
      justify-content: flex-end;
      width: 160px;

      @media screen and (max-width: $tabletWidth) {
        width: 120px;
      }
    }
  }
}

.Divider {
  width: 2px;
  height: 170%;
  background-color: #CECECE;
}
