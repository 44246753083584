@import '../../css/constants.module';

.Info {
  margin-bottom: 20px;

  .Subtitle {
    font-size: 18px;
    color: #3F5A6A;
  }

  .Text {
    margin: 0;
    font-size: 14px;
  }
}
