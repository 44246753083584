@import '../../css/constants.module';

.FirstSectionContainer {
  width: 100%;
  display: flex;
  flex-direction: column;
  justify-content: center;
  min-height: calc(100vh - #{$headerDesktopHeight});
  padding-top: 40px;

  @media screen and (max-width: $tabletWidth) {
    min-height: calc(100vh - #{$headerTabletHeight});
    display: block;
    padding-top: 50px;
  }

  @media screen and (max-width: $mobileWidth) {
    padding-top: 30px;
  }

  .FirstSectionContent {
    display: flex;
    justify-content: space-between;

    @media screen and (max-width: $tabletWidth) {
      display: block;
    }

    .Left {
      width: 40%;

      @media screen and (max-width: $tabletWidth) {
        width: 80%;
        margin: 0 auto;
      }

      @media screen and (max-width: $mobileWidth) {
        width: 90%;
        margin: 0 auto;
      }

      .Animation {
        display: none;

        @media screen and (max-width: $mobileWidth) {
          display: block;
        }
      }

      .LeftContent {

        .Text {
          margin-top: 50px;

          @media screen and (max-width: $mobileWidth) {
            margin-top: 20px;
          }
        }

        .Link {
          margin-top: 20px;
          display: inline-block;

          @media screen and (max-width: $mobileWidth) {
            margin-top: 10px;
          }
        }

        .Button {
          margin-top: 40px;
          display: block;
          height: 80px;

          @media screen and (max-width: $tabletWidth) {
            margin-left: auto;
            margin-right: auto;
          }

          .TextButton {
            text-transform: capitalize;
            margin: 0 20px;
            line-height: 2em;
          }
        }

        .LogosContainer {
          margin-top: 35px;
          display: flex;
          width: 100%;
          justify-content: space-between;
          align-items: center;

          @media screen and (max-width: $mobileWidth) {
            margin-top: 40px;
            display: grid;
            grid-template-columns: 100%;
            grid-gap: 30px;
          }

          img {
            width: 20%;
            height: auto;
            cursor: pointer;

            @media screen and (max-width: $mobileWidth) {
              width: 100%;
              margin: 0 auto;

              &.inappLogo {
                width: 45%;
                margin-bottom: 10px;
              }

              &.oecdLogo {
                width: 55%;
                margin-top: 15px;
              }
            }
          }
        }
      }
    }

    .Right {
      width: 60%;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
        max-width: 640px;
        margin: 0 auto;
        margin-top: 40px;
      }

      @media screen and (max-width: $mobileWidth) {
        display: none;
      }
    }
  }
}

.SecondSectionContainer {
  width: 100%;
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;

  @media screen and (max-width: $mobileWidth) {
    margin-top: 30px;
  }

  .ChoiceContainer {
    width: 100%;
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    margin-top: 60px;

    @media screen and (max-width: $mobileWidth) {
      display: block;
      margin-top: 40px;
    }
  }
}

.UpperRow {
  justify-content: center;
  display: flex;
  margin: 20px;
  padding: 30px;
}
