@import "constants.module";

.DF {
  display: flex;
}

.DB {
  display: block !important;
}

.FDR {
  flex-direction: row;
}

.TL {
  text-align: left;
}

.CW {
  color: white
}

.AIFS {
  align-items: flex-start !important;
}

.AIFE {
  align-items: flex-end !important;
}

.JCSB {
  justify-content: space-between !important;
}

.PT10 {
  padding-top: 10px;
}

.W100 {
  width: 100% !important;
}

.MT20 {
  margin-top: 20px !important;
}

.MT30 {
  margin-top: 30px !important;
}

.MB30 {
   margin-bottom: 30px !important;
 }
