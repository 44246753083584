@import '../../../../css/constants.module';

.Container {
  width: 100%;
  padding-bottom: 40px;

  .Paper {
    padding: 40px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $tabletWidth) {
      display: block;
    }

    @media screen and (max-width: $mobileWidth) {
      padding: 20px;
    }

    .Left {
      width: 25%;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
      }

      .Region {
        display: flex;
        align-items: center;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }

      .CurrentView {
        font-size: 13px;
      }

      .RegionSelect {
        display: none;

        @media screen and (max-width: $tabletWidth) {
          display: block;
          margin-top: 20px;
        }
      }

      .Map {
        margin-top: 20px;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }
    }

    .Right {
      width: 75%;
      padding-left: 20px;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
      }
    }

    .SearchContainer {
      display: flex;
      flex-direction: row;
      margin: 20px 0 10px 0;

      @media screen and (max-width: $mobileWidth) {
        display: block;
      }

      .Input {
        width: auto;
        flex: 1;
      }

      .SearchFab {
        margin-left: 10px;

        @media screen and (max-width: $mobileWidth) {
          display: none;
        }
      }

      .SearchButton {
        display: none;

        @media screen and (max-width: $mobileWidth) {
          display: block;
          margin: 20px auto 0 auto;
          width: 100%;
        }
      }
    }

    .Row {
      display: flex;
      flex-direction: row;
      align-items: center;
      margin: 10px 0px;
    }

    .Column {
      display: flex;
      flex-direction: column;
    }
  }
}

.Info {
  color: #9A9A9A !important;
  font-size: 16px;
}
