@font-face {
    font-family: 'Tofino-Bold';
    src: url('../fonts/Tofino-Bold.otf')  format('opentype'),
    url('../fonts/Tofino-Bold.woff') format('woff'),
    url('../fonts/Tofino-Bold.ttf')  format('truetype'),
    url('../fonts/Tofino-Bold.svg#Tofino-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tofino-Regular';
    src: url('../fonts/Tofino-Regular.otf')  format('opentype'),
    url('../fonts/Tofino-Regular.woff') format('woff'),
    url('../fonts/Tofino-Regular.ttf')  format('truetype'),
    url('../fonts/Tofino-Regular.svg#Tofino-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tofino-Medium';
    src: url('../fonts/Tofino-Medium.otf')  format('opentype'),
    url('../fonts/Tofino-Medium.woff') format('woff'),
    url('../fonts/Tofino-Medium.ttf')  format('truetype'),
    url('../fonts/Tofino-Medium.svg#Tofino-Medium') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Regular';
    src: url('../fonts/Nunito-Regular.woff') format('woff'),
    url('../fonts/Nunito-Regular.ttf')  format('truetype'),
    url('../fonts/Nunito-Regular.svg#Nunito-Regular') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Tofino-Book';
    src: url('../fonts/Tofino-Book.otf')  format('opentype'),
    url('../fonts/Tofino-Book.woff') format('woff'),
    url('../fonts/Tofino-Book.ttf')  format('truetype'),
    url('../fonts/Tofino-Book.svg#Tofino-Book') format('svg');
    font-weight: normal;
    font-style: normal;
}

@font-face {
    font-family: 'Nunito-Bold';
    src: url('../fonts/Nunito-Bold.woff') format('woff'),
    url('../fonts/Nunito-Bold.ttf')  format('truetype'),
    url('../fonts/Nunito-Bold.svg#Nunito-Bold') format('svg');
    font-weight: normal;
    font-style: normal;
}