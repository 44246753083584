@import "../../css/constants.module";

.IconButton {
  height: 56px !important;
  width: 56px !important;
  border-radius: 28px !important;
  display: flex;
  align-items: center;
  justify-content: center;

  @media screen and (max-width: $tabletWidth) {
    width: 50px !important;
    height: 50px !important;
    border-radius: 25px !important;
  }

  .IconImage {
    height: 45px !important;
    width: 45px !important;

    @media screen and (max-width: $tabletWidth) {
      width: 35px !important;
      height: 35px !important;
    }
  }
}
