@import '../../../../css/constants.module';

.Container {
  width: 100%;
  padding-bottom: 40px;

  .JustifiedRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Spot {
    margin: 5px 5px 5px 20px;
  }

  .Paper {
    padding: 40px;

    @media screen and (max-width: $mobileWidth) {
      padding: 20px;
    }

    .SearchContainer {
      display: flex;
      flex-direction: row;
    }

    .MapContainer {
      padding: 60px 90px 60px 30px;
    }

    .Column {
      width: 100%;
      display: flex;
      flex-direction: column;

      .Row {
        width: 100%;
        display: flex;
        flex-direction: row;
        align-items: flex-start;
        margin-bottom: 10px;

        @media screen and (max-width: $mobileWidth) {
          display: block;
        }

        .Input {
          width: auto;
          flex: 1;
        }

        .SearchFab {
          margin-left: 10px;

          @media screen and (max-width: $mobileWidth) {
            display: none;
          }
        }

        .SearchButton {
          display: none;

          @media screen and (max-width: $mobileWidth) {
            display: block;
            margin: 20px auto 0 auto;
          }
        }
      }
    }

    .ActiveText {
      cursor: pointer;
      text-decoration: underline;
    }

  }
}
