@import '../../../../css/constants.module';
@import '../../../../css/mixin.module';

.Container {
  width: 100%;
  padding-bottom: 40px;

  .Paper {
    padding: 40px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $mobileWidth) {
      padding: 20px;
    }

    .Progress {
      margin: 100px auto;
    }

    .Row {
      display: flex;
      flex-direction: row;
      align-items: center;
    }

    .Region {
      display: flex;
      align-items: center;

      @media screen and (max-width: $tabletWidth) {
        display: none;
      }
     }

    @media screen and (max-width: $tabletWidth) {
      display: block;
    }

    .OrderRow {
      display: none;
      @media screen and (max-width: $tabletWidth) {
        display: flex;
        justify-content: flex-end;
        margin-top: 20px;
      }
      .OrderFab {
        &:not(:last-child) {
          margin-right: 10px;
        }
      }
    }

    .Left {
      width: 25%;

      .CurrentView {
        font-size: 13px;
      }

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
      }

      .RegionSelect {
        display: none;

        @media screen and (max-width: $tabletWidth) {
          display: block;
          margin-top: 20px;
        }
      }

      .Map {
        margin-top: 20px;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }
    }

    .Right {
      width: 75%;
      padding-left: 20px;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
      }

      .Table {

        @media screen and (max-width: 600px) {
          display: none;
          .TypographyTofinoMedium {
            font-family: "Tofino-Medium", sans-serif;
            font-size: 4px;
            opacity: 1;
          }
          .TypographyTofinoRegular {
            font-size: 14px;
            opacity: 1;
          }
        }

        .TypographyTofinoRegular {
          font-size: 14px;
          opacity: 1;

          @media screen and (max-width: $mobileWidth) {
            font-size: 10px;
          }
        }
      }

      .LinkCell {
        white-space: nowrap;
      }
    }

    .AccordionContainer {
      display: none;

      @media screen and (max-width: $tabletWidth) {
        display: block;
      }

      .ExpansionPanel {
        box-shadow: none;
      }

      .ExpansionPanelSummaryFill {
        background-color: #F7F6FB;
      }


    }

    .ActiveText {
      cursor: pointer;
      text-decoration: underline;
    }

    .Link {
      cursor: pointer;
    }

    .job{
      color: #459ddb;
    }

    .TableCellExpansion {
      @media screen and (max-width: $mobileWidth) {
        padding: 0;
        padding-right: 10px;
      }
    }

    .TypographyTofinoRegular {
      font-size: 14px;
      opacity: 1;

      @media screen and (max-width: $mobileWidth) {
        font-size: 12px;
      }
    }

    .TypographyTofinoMedium {
      font-family: "Tofino-Medium", sans-serif;
      font-size: 14px;
      opacity: 1;

      @media screen and (max-width: $mobileWidth) {
        font-size: 12px;
      }
    }
  }
}
