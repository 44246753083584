@import "../../css/constants.module";

.Container {
  background-color: #3f5a6b;
  min-height: 100vh;
  padding-bottom: 40px;

  .Content {
    padding-top: 40px;
    overflow: hidden;

    .Paper {
      padding: 40px;
      display: block;

      @media screen and (max-width: $mobileWidth) {
        width: 100%;
        padding: 10px;
      }

      .Logo {
        height: 90px;
      }

      .Title {
        margin-top: 30px;
      }

      .Text {
        margin-top: 40px;
      }

      .LinkText {
        color: #5bbb73;
      }
    }

    .RightPaper {
      padding: 40px;
      margin-left: 20px;

      @media screen and (max-width: $mobileWidth){
        margin-left: 0;
        margin-top: 20px;
      }

      .RightLogo {
        width: 100%;
        max-width: 120px;
        margin: 0 auto;
        display: block;
        margin-bottom: 40px;
      }
    }
  }
}
