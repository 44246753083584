@import "../../css/constants.module";

.Container {
  width: 100%;
  margin-top: 40px;
  margin-bottom: 30px;
  display: flex;
  flex-direction: row;
  align-items: center;
  justify-content: space-between;

  .Button {
    color: white;

    @media screen and (max-width: $mobileWidth) {
      display: none;
    }
  }

  .Icon {
    font-size: 33px;
  }

  .Subtitle2 {
    color: white;
      @media screen and (max-width: $tabletWidth) {
        font-size: 21px;
      }
    }
}
