@import '../../../../css/constants.module';
@import '../../../../css/mixin.module';

.Container {
  width: 100%;
  padding-bottom: 40px;

  .Link {
    margin-top: 20px;
    display: inline-block;
  }

  .textlink{
    display: inline;
    cursor: pointer;
  }
  .Paper {
    padding: 40px;

    @media screen and (max-width: $mobileWidth) {
      padding: 20px;
    }

    .ResultRow {
      display: flex;
      margin: 40px auto;
      width: 100%;
      overflow: hidden;

      @media screen and (max-width: $tabletWidth) {
        display: block;
      }

      .CoursesContainer {
        width: 280px;
        padding: 20px;
        background-color: #f0f4f7;
        border-radius: 4px;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }

    }

    .Title {
      margin-top: 20px;
    }

    .CoursesContainerMobile {
      display: none;

      @media screen and (max-width: $tabletWidth) {
        display: block;
      }
    }

    .CourseLink {
      font-weight: bold;
      display: block;
    }

    .TableDisclaimerText {
      font-size: 11px;
    }

    .TableTitle {
      display: flex;
      flex-direction: row;
      background: #f0f4f7;
      padding: 5px 20px;
      color: #3b536b;
      align-items: center;
      justify-content: space-between;

      .TableTitleImg {
        margin-right: 15px;
      }

      .TableTitleText {
        display: flex;
        flex-direction: row;
        align-items: center;
      }

    @media screen and (max-width: $mobileWidth) {
      flex-direction: column;

      .TableTitleText {
          display: flex;
          flex-direction: column;
          justify-content: center;
          text-align: center;
          font-size: 14px;
        }

        .TableTitleImg {
          margin-right: 0;
        }

        .AlmaLaureaLink {
          font-size: 12px;
        }
      }
    }

    .TableContainer {
      margin-left: 20px;
      width: calc(100% - 280px);

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
        margin: 20px 0 0 0;
      }

      .Table {
        width: 100%;

        @media screen and (max-width: $tabletWidth) {
          margin-bottom: 20px;
        }

        .TableRow {
          width: 100%;
          display: flex;

          @media screen and (max-width: $tabletWidth) {
            display: block;
          }

          .TableCell {
            width: 50%;
            padding: 15px 10px 15px 10px;
            border-bottom: 0px;

            @media screen and (max-width: $tabletWidth) {
              border: 1px solid #f0f4f7;
              width: 100%;
            }

            .SectionTitle {
              display: flex;
              flex-direction: row;
              align-items: center;
              min-height: 65px;
              justify-content: flex-start;
            }

            .CellTitle {
              margin-left: 15px;
            }
          }

          .TableCellLeft {
            border-right: 1px solid #f0f4f7;
          }

          .TableCellNotLastRow {
            border-bottom: 1px solid #f0f4f7;
          }
        }
      }
    }

    .SideBarLink {
      display: block;
    }

    .AlmaLaureaLink {
      font-size: 14px;
    }

    .AlmaLaureaPaginationLink {
      font-size: 12px;
      display: inline-block;
      margin-right: 10px;
      margin-top: 10px;
      cursor: pointer;
    }

    .Typography {
      font-weight: bold;
      font-size: 16px;
      color: black;
    }

  }
}

.CircularProgress {
  margin: 100px auto;
}

@keyframes blinker {
  50% {
    opacity: 0;
  }
}

.FontReducer {
  @media screen and (max-width: $tabletWidth) {
    font-size: .85em !important;
  }
}
