@import '../../css/constants.module';

.ExpansionPanel {
  box-shadow: none !important;
  margin: 0 !important;

  .ExpansionPanelSummary {
    &>div:first-child {
      margin: 0 !important;
    }
  }

  .ExpansionPanelDetails {
    padding: 15px !important;
    background-color: rgba(10, 15, 25, 0.05);
  }

  &:before {
    display: none !important;
  }

  &>div:first-child {
    width: 250px !important;
    padding: 0 !important;
  }
}

.SkillSvgContainer {
  display: flex;
  height: 25px;
  align-items: center;
  margin: 10px 0;

  .SkillsSvg {
    width: 25px;
    height: auto;
    margin-right: 10px;
  }
}
