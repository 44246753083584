@import '../../../../css/constants.module';

.Paper {
  padding: 8px;
  width: 30%;
  max-width: 350px;
  max-height: 500px;

  @media screen and (max-width: $mobileWidth) {
    width: 100%;
    max-width: 100%;
    margin-bottom: 20px;
  }

  .Column {
    width: 100%;
    height: 100%;
    border-radius: 5px;
    padding: 40px;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;

    .AnimationContainer {
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;

      @media screen and (max-width: $mobileWidth) {
        display: flex;
        flex-direction: row;
        justify-content: space-around;
        align-items: center;

        & > div {
          margin: 0 !important;
        }
      }

      .Animation {
        width: 120px;
        height: 120px;

        @media screen and (max-width: $mobileWidth) {
          width: 75px;
          height: 75px;
        }
      }
    }

    .Button {
      margin-top: 30px;
      height: 60px;

      @media screen and (max-width: $mobileWidth) {
        margin-top: 20px;
        margin-bottom: 40px;
      }
    }

    .TextContainer {
      display: flex;
      align-items: center;
      justify-content: center;
      min-height: 100px;

      @media screen and (max-width: $mobileWidth) {
        display: block;
        min-height: 0;
      }

      .Text {
        color: white;
        text-align: center;
        text-transform: uppercase;

        @media screen and (max-width: $mobileWidth) {
          margin-bottom: 0 !important;
        }
      }
    }

    .Description {
      text-align: center;
      color: white;

      @media screen and (max-width: $mobileWidth) {
        margin-top: 20px;
      }
    }
  }
}
