.Container {
  width: 100%;

  .Downshift {
  }

  .TextField {
    border-radius: 3px;
    text-align: center;
  }

  .Suggestions {
    position: absolute;
    z-index: 10;
    background: #fff;
  }
  .ChipsContainer {
    .Chip {
      margin-top: 10px;
      margin-right: 10px;
    }
  }
}
