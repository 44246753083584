@import '../../../../css/constants.module';
@import '../../../../css/mixin.module';

.Selected {
  color: red !important;
}

.NotSelected {

}

.Container {
  width: 100%;
  padding-bottom: 40px;

  .JustifiedRow {
    display: flex;
    flex-direction: row;
    align-items: center;
    justify-content: space-between;
  }

  .Spot {
    margin: 5px 5px 5px 20px;
  }

  .Paper {
    padding: 40px;
    display: flex;
    flex-direction: row;

    @media screen and (max-width: $tabletWidth) {
      display: block;
    }

    @media screen and (max-width: $mobileWidth) {
      padding: 20px;
    }

    .Left {
      width: 25%;

      .CurrentView {
        font-size: 13px;
      }

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
      }

      .Region {
        display: flex;
        align-items: center;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }

      .RegionSelect {
        display: none;

        @media screen and (max-width: $tabletWidth) {
          display: block;
          margin-top: 20px;
        }
      }

      .Map {
        margin-top: 20px;

        @media screen and (max-width: $tabletWidth) {
          display: none;
        }
      }
    }

    .Right {
      width: 75%;
      padding-left: 20px;

      @media screen and (max-width: $tabletWidth) {
        width: 100%;
        padding-left: 0;
        margin-top: 20px;
      }

      .DegreeSwitch {@media screen and (max-width: $mobileWidth) {
          display: none;
        }
      }

      .DegreeSelect {
        display: none;

        @media screen and (max-width: $mobileWidth) {
          display: block;
        }
      }

      .SearchContainer {
        display: flex;
        flex-direction: row;
        margin: 10px 0;

        @media screen and (max-width: $mobileWidth) {
          display: block;
        }

        .Input {
          width: auto;
          flex: 1;
        }
      }

      .SearchButton {
        display: block;
        margin: 20px 0 0 0;
        width: 200px;

        @media screen and (max-width: $tabletWidth) {
          margin: 20px auto 0 auto;
          width: 100%;
        }
      }
    }
  }

  .DegreeType {
    margin: 30px 0;
  }

  .DownloadLink {
    color: white;
    text-decoration: underline !important;
    margin-top: 10px;
    margin-right: 10px;
    text-align: right;
    display: none;

    @media screen and (max-width: $mobileWidth) {
      display: block;
    }
  }
}

.TextField {
  @media screen and (max-width: $mobileWidth) {
    input {
      padding: 16.5px 14px
    }
    label {
      font-size: .8em !important;
      text-align: left !important;
      width: 90%;
    }


    &.multiline {
      label {
        margin-top: -5px;
      }
    }
  }
}
